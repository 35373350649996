import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';
import { CourseSection } from '../Student/CourseSection';
import { FlightTrainingCourse } from '../Student/FlightTrainingCourse';
import { TlnImportLog } from './TlnImportLog';

export class TlnRowLog {
  @Type(/* istanbul ignore next */ () => Number)
  rowNumber!: number;
  @Type(/* istanbul ignore next */ () => Number)
  id!: number;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  deletedAt?: Date;

  @Type(/* istanbul ignore next */ () => Date)
  createDateTime!: Date;

  @Type(/* istanbul ignore next */ () => Number)
  createdBy?: number | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  lastChangedDateTime!: Date;

  @Type(/* istanbul ignore next */ () => Number)
  lastChangedBy?: number | null;
  importLastName!: string | null;
  importFirstName!: string | null;
  tlnId!: string | null;
  section!: CourseSection | null;
  course!: FlightTrainingCourse | null;
  createdCourse!: boolean | null;
  dateShifted!: boolean | null;
  candidate!: BaseCandidate | null;
  addedToOutPro!: boolean | null;
  tlnImportLog!: TlnImportLog;
  errorMessage!: string | null;

  constructor(partial?: Partial<TlnRowLog>) {
    if (partial) {
      Object.assign(this, plainToClass(TlnRowLog, partial));
    }
  }
}
