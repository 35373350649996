import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { SearchTlnRowLogDto } from '@/models/Dtos/searchTlnRowLogDto';
import { TlnRowLog } from '@/models/Entities/Imports/TlnRowLog';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { TlnRowLogTableData } from './TlnRowLogTableData';
import TextWithColorElementComponent from '@/components/Table/Elements/TextWithColorElementComponent.vue';
import IconTableComponent from '@/components/Table/Elements/IconTableComponent.vue';

export const tlnRowLogTableConfigBuilder: TableConfigBuilder<
  TlnRowLogTableData,
  TlnRowLog,
  never,
  SearchTlnRowLogDto
> = (): ITableConfiguration<TlnRowLogTableData, TlnRowLog, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'No.',
        render: {
          componentRef: TextWithColorElementComponent,
          componentProps: (
            rowData: TlnRowLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.row,
              color: 'black'
            };
          }
        },
        sortKey: 'rowNumber',
        defaultPinned: true
      },
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: TlnRowLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.candidateName,
              linkTo: rowData.candidate
                ? {
                    name: Routes.APPLICANT_DETAILS,
                    params: { id: rowData.candidate.id }
                  }
                : {}
            };
          }
        },
        sortKey: 'importLastName'
      },
      {
        columnTitle: 'TLN',
        render: 'tlnId',
        sortKey: 'tlnId'
      },
      {
        columnTitle: 'Date Shift In TLN',
        render: {
          componentRef: IconTableComponent,
          componentProps: (
            rowData: TlnRowLogTableData
          ): Record<string, unknown> => {
            return {
              iconState: rowData.dateShifted,
              iconIfTrue: 'circleCheck',
              show: rowData.dateShifted,
              width: 16,
              height: 16
            };
          }
        },
        sortKey: 'addedToOutPro'
      },
      {
        columnTitle: 'Classes Created',
        render: 'classesCreated',
        sortKey: 'createdCourse'
      },
      {
        columnTitle: 'Moved To Outprocessing',
        render: {
          componentRef: IconTableComponent,
          componentProps: (
            rowData: TlnRowLogTableData
          ): Record<string, unknown> => {
            return {
              iconState: rowData.addedToOutPro,
              iconIfTrue: 'circleCheck',
              show: rowData.addedToOutPro,
              width: 16,
              height: 16
            };
          }
        },
        sortKey: 'addedToOutPro'
      },
      {
        columnTitle: 'Errors',
        render: 'errors',
        sortKey: 'errorMessage'
      }
    ]
  };
};
