import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { TlnRowLog } from '@/models/Entities/Imports/TlnRowLog';
import { DisplayNameUtil } from '@/util/displayName';

export class TlnRowLogTableData {
  entityId: number;
  tlnId: string | null;
  dateShifted: boolean | null;
  tlnsDeleted: string | null;
  classesCreated?: string | null;
  addedToOutPro: boolean | null;
  errors: string | null;
  candidate: BaseCandidate | null;
  candidateName: string | null;
  row: number;

  constructor(tlnRowLog: TlnRowLog) {
    this.entityId = tlnRowLog.id;
    this.tlnsDeleted = null;
    this.addedToOutPro = tlnRowLog.addedToOutPro;
    this.dateShifted = tlnRowLog.dateShifted;
    this.tlnId = tlnRowLog.tlnId;
    if (tlnRowLog.createdCourse) {
      this.classesCreated = tlnRowLog.course?.courseNumber;
    } else {
      this.classesCreated = null;
    }
    this.errors = tlnRowLog.errorMessage;
    this.candidate = tlnRowLog.candidate;
    this.row = tlnRowLog.rowNumber + 1;
    this.candidateName = DisplayNameUtil.getFullName(
      tlnRowLog.importFirstName,
      tlnRowLog.importLastName
    );
    if (tlnRowLog.candidate) {
      this.candidateName = DisplayNameUtil.getFullName(
        tlnRowLog.candidate.identificationInformationFirstname,
        tlnRowLog.candidate.identificationInformationLastname
      );
    }
  }
}
