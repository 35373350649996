var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "div",
        [
          _vm.iconState
            ? _c("icon-component", {
                attrs: {
                  name: _vm.iconIfTrue,
                  width: _vm.width,
                  height: _vm.height,
                  fill: _vm.fillIfTrue,
                  stroke: _vm.strokeIfTrue,
                },
              })
            : _c("icon-component", {
                attrs: {
                  name: _vm.iconIfFalse,
                  width: _vm.width,
                  height: _vm.height,
                  fill: _vm.fillIfFalse,
                  stroke: _vm.strokeIfFalse,
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }