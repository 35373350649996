import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchTlnRowLogDto } from '@/models/Dtos/searchTlnRowLogDto';
import { TlnRowLog } from '@/models/Entities/Imports/TlnRowLog';
import { TlnRowLogTableData } from './TlnRowLogTableData';

export class TlnRowLogDataProvider extends TableDataProvider<
  TlnRowLogTableData,
  TlnRowLog,
  SearchTlnRowLogDto
> {
  protected transformer = (t: TlnRowLog): TlnRowLogTableData =>
    new TlnRowLogTableData(t);

  protected queryHandler = async (
    searchDto: SearchTlnRowLogDto
  ): Promise<SearchResponseDto<TlnRowLog>> => {
    const results: SearchResponseDto<TlnRowLog> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'tln-row-log', query: searchDto, model: TlnRowLog }
    );
    return results;
  };
}
