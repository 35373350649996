
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';

@Component<IconTableComponent>({
  components: {
    IconComponent
  }
})
export default class IconTableComponent extends Vue {
  @Prop({ default: false })
  iconState!: boolean;

  @Prop({ default: true })
  show?: boolean;

  @Prop({ default: 0 })
  width!: number;

  @Prop({ default: 0 })
  height!: number;

  @Prop({ default: null })
  iconIfTrue!: string;

  @Prop({ default: null })
  fillIfTrue!: string;

  @Prop({ default: null })
  strokeIfTrue!: string;

  @Prop({ default: 'circleCross' })
  iconIfFalse?: string;

  @Prop({ default: null })
  fillIfFalse!: string;

  @Prop({ default: null })
  strokeIfFalse!: string;
}
