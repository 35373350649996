
import IconComponent from '@/components/IconComponent.vue';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import { TlnRowLogDataProvider } from '@/components/Table/TLN/TlnRowLog/TlnRowLogDataProvider';
import { tlnRowLogTableConfigBuilder } from '@/components/Table/TLN/TlnRowLog/TlnRowLogTableConfigBuilder';
import { TlnRowLogTableData } from '@/components/Table/TLN/TlnRowLog/TlnRowLogTableData';
import FetchMixin from '@/mixins/FetchMixin';
import { SearchTlnImportLogDto } from '@/models/Dtos/searchTlnImportLogDto';
import { SearchTlnRowLogDto } from '@/models/Dtos/searchTlnRowLogDto';
import { TlnImportLog } from '@/models/Entities/Imports/TlnImportLog';
import { TlnRowLog } from '@/models/Entities/Imports/TlnRowLog';
import Routes from '@/router/Routes';
import { formatDate, formatTime } from '@/services/formatService';
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';

@Component<TlnRowLogPage>({
  components: {
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent,
    LinkElementComponent,
    IconComponent
  }
})
export default class TlnRowLogPage extends Mixins(Vue, FetchMixin) {
  routes = Routes;

  @Prop()
  readonly tlnImportId!: number;

  importDateTime = '';

  searchService: TableSearchService<
    TlnRowLogTableData,
    TlnRowLog,
    never,
    SearchTlnRowLogDto
  > | null = null;

  async created(): Promise<void> {
    await this.queryImportDateTime();
    this.searchService = new TableSearchService<
      TlnRowLogTableData,
      TlnRowLog,
      never,
      SearchTlnRowLogDto
    >(
      this.$store,
      new TlnRowLogDataProvider(this.$store),
      { tlnImportId: this.tlnImportId, relations: ['candidate', 'course'] },
      { defaultSortProperty: 'rowNumber' },
      tlnRowLogTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
    await this.searchService.queryData();
  }

  async queryImportDateTime(): Promise<void> {
    const query: SearchTlnImportLogDto = {
      id: this.tlnImportId
    };
    const importedLog = await this.getOne({
      path: 'tln-import-log',
      query,
      model: TlnImportLog
    });
    if (importedLog.createDateTime) {
      const date = formatDate(importedLog.createDateTime);
      const time = formatTime(importedLog.createDateTime);
      this.importDateTime = `${date} at ${time}`;
    }
  }
}
